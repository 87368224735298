import React, { useEffect } from "react";
import Seo from "../components/Seo";
import Layout from "../components/layout";
import { graphql, Link } from "gatsby";
import { useModal } from "../../provider";

import {
    LocationMarkerIcon,
    CalendarIcon,
    CheckIcon,
} from "@heroicons/react/outline";
import InstagramFeed from "../components/InstagramFeed";
import Timetable from "../components/Timetable";
import WorkoutModal from "../components/Modal/WorkoutModal";
import HeroHeader from "../components/HeroHeader";
import TrainerIcon from "../components/TrainerIcon";
import MapBox from "../components/MapBox";
import { gaEvent } from "../helpers";
import Outcomes from "../sections/Outcomes";

const ourLocationId = "location";
const timetableId = "training-schedule";

const LocationPage = ({ data }) => {
    const location = data.markdownRemark;
    const location_slug = location.fields.slug
        .replace("/location/", "")
        .replace("/", "");
    const all_trainers = data.allSanityTrainer.edges;
    const trainers = all_trainers.filter((trainer) =>
        trainer.node.locations.some(
            (l) => l.location.slug.current === location_slug
        )
    );

    const modal = useModal();
    useEffect(() => {
        modal.setSelectedLocationSlug(location.frontmatter.slug);
        return () => modal.setSelectedLocationSlug(null);
    }, [location.frontmatter.slug, modal]);

    return (
        <Layout>
            <Seo
                title={location.frontmatter.title}
                meta_title={location.frontmatter.meta_title}
                meta_desc={location.frontmatter.meta_description}
                slug={location.fields.slug}
            />
            <main>
                <div>
                    <HeroHeader
                        title={location.frontmatter.title}
                        background_image={location.frontmatter.cover}
                        options={[
                            { title: "Our Location", to: `#${ourLocationId}` },
                            { title: "Timetable", to: `#${timetableId}` },
                            {
                                title: "Free Trial",
                                highlighted: true,
                                onClick: () => {
                                    gaEvent(
                                        "Button",
                                        "FreeTrial",
                                        "free trial - hero header"
                                    );
                                    modal.openFreeTrialModal();
                                },
                            },
                        ]}
                    />

                    {/* Logo cloud */}
                    <div className="bg-gray-100">
                        <div className="relative lg:mx-auto px-4 lg:max-w-7xl lg:px-8 grid grid-cols-3 gap-8 py-4 lg:py-16 text-center">
                            <Link
                                to={`#${ourLocationId}`}
                                className="-m-3 p-3 flex flex-col items-center rounded-lg hover:bg-gray-50"
                            >
                                <div className="flex-shrink-0 flex items-center justify-center h-30 w-30 text-feat-darkgrey">
                                    <LocationMarkerIcon
                                        className="h-6 w-6"
                                        aria-hidden="true"
                                    />
                                </div>
                                <div className="text-sm sm:text-base font-light pt-2 text-gray-900">
                                    {location.frontmatter.location}
                                </div>
                            </Link>
                            <Link
                                to={`#${timetableId}`}
                                className="-m-3 p-3 flex flex-col items-center rounded-lg hover:bg-gray-50"
                            >
                                <div className="flex-shrink-0 flex items-center justify-center h-30 w-30 text-feat-darkgrey">
                                    <CalendarIcon
                                        className="h-6 w-6"
                                        aria-hidden="true"
                                    />
                                </div>
                                <div className="text-sm font-light pt-2 text-gray-900">
                                    Schedule
                                </div>
                            </Link>
                            <button
                                onClick={() => {
                                    gaEvent(
                                        "Button",
                                        "FreeTrial",
                                        "free trial - location events"
                                    );
                                    modal.openFreeTrialModal();
                                }}
                                className="-m-3 p-3 flex flex-col items-center rounded-lg hover:bg-gray-50"
                            >
                                <div className="flex-shrink-0 flex items-center justify-center h-30 w-30 text-feat-darkgrey">
                                    <CheckIcon
                                        className="h-6 w-6"
                                        aria-hidden="true"
                                    />
                                </div>
                                <div className="text-sm font-light pt-2 text-gray-900">
                                    Start Trial
                                </div>
                            </button>
                        </div>
                    </div>
                    {/** end Clouds */}

                    {/* Training with FEAT Fitness */}
                    <div className="py-12 md:py-16 px-6 lg:px-32 text-feat-darkgray">
                        <h2 className="text-heading uppercase text-3xl sm:text-4xl text-feat-darkgray text-center">
                            Training With FEAT Fitness
                        </h2>
                        <p className="pt-8 font-light">
                            The FEAT Fitness training program is an overall
                            strength and conditioning program that can be
                            tailored to any fitness level. Our training is
                            perfect if you're looking to get started, looking to
                            mix up your current training routine, or just
                            curious what training with a group of fun and
                            amazing locals is like.
                        </p>
                    </div>
                    {/* END Training with FEAT Fitness */}

                    {/* Map and Location Details */}
                    <div
                        id={ourLocationId}
                        className="flex flex-col-reverse h-screen-navbar lg:h-auto lg:mx-auto lg:grid lg:grid-cols-2 lg:gap-8 bg-gray-100 pt-12 lg:pt-0"
                    >
                        <div className="flex-1 h-full z-0 overflow-y-auto focus:outline-none">
                            <MapBox
                                lat={location.frontmatter.lat}
                                lng={location.frontmatter.lng}
                                markers={[
                                    {
                                        lat: location.frontmatter.lat,
                                        lng: location.frontmatter.lng,
                                    },
                                ]}
                            />
                        </div>
                        <div className="flex-1 pb-12 lg:py-24 px-6 lg:px-0 text-feat-darkgrey">
                            <h2 className="font-heading uppercase text-3xl">
                                {location.frontmatter.location}
                            </h2>
                            <div
                                className="font-base pt-8 font-light"
                                dangerouslySetInnerHTML={{
                                    __html: location.html,
                                }}
                            />
                        </div>
                    </div>
                    {/* END Map and Location Details */}

                    {/* Our Schedule */}
                    <div
                        id={timetableId}
                        className="py-12 md:py-16 px-6 sm:px-32 text-feat-darkgray"
                    >
                        <h2 className="text-heading uppercase text-3xl sm:text-4xl text-feat-darkgray text-center mb-2">
                            Our Training Schedule
                        </h2>
                        <div className="py-8 text-center">
                            <Timetable
                                timetable={location.frontmatter.timetable}
                            />
                        </div>
                    </div>
                    {/* END Our Schedule */}

                    {/* Our Trainers */}
                    <div className="py-24 sm:px-32 px-4 text-feat-darkgray bg-feat-lightgray">
                        <h2 className="text-heading uppercase text-4xl text-feat-darkgray text-center pb-0 sm:pb-4">
                            Trainers At {location.frontmatter.location}
                        </h2>
                        <p className="pt-8 text-center">
                            {/* <ul className="mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:grid-cols-3 lg:max-w-5xl"> */}
                            <ul className="mx-auto flex flex-wrap justify-center lg:max-w-5xl">
                                {trainers.map((person) => (
                                    <li key={person.node.title} className="p-6">
                                        <TrainerIcon trainer={person.node} />
                                    </li>
                                ))}
                            </ul>
                        </p>
                    </div>
                    {/* END Our Trainers */}

                    <Outcomes location={location.frontmatter.location} />
                    <InstagramFeed
                        className="bg-feat-blue-200"
                        feedId={location.frontmatter.instagram_feed_id}
                    />
                </div>
            </main>
            <WorkoutModal />
        </Layout>
    );
};

export default LocationPage;

export const pageQuery = graphql`
    query LocationByID($id: String!) {
        markdownRemark(id: { eq: $id }) {
            id
            html
            fields {
                slug
            }
            frontmatter {
                title
                location
                slug
                instagram_feed_id
                meta_title
                meta_description
                lat
                lng
                google_map_id
                timetable {
                    day_of_week
                    sessions {
                        time_slot_name
                        session
                        session_short_code
                        type
                    }
                }
            }
        }
        allSanityTrainer(
            filter: { slug: { current: { ne: null } } }
            sort: { order: ASC, fields: [order] }
        ) {
            edges {
                node {
                    title
                    slug {
                        current
                    }
                    locations {
                        location {
                            slug {
                                current
                            }
                        }
                    }
                    funnyPhoto {
                        asset {
                            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
                        }
                    }
                    smilePhoto {
                        asset {
                            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
                        }
                    }
                    _rawBody(resolveReferences: { maxDepth: 5 })
                }
            }
        }
    }
`;

import classNames from "classnames";
import React from "react";
import { useModal } from "../../../provider";

export default function Timetable({ timetable }) {
  const modal = useModal();
  return (
    <div className="grid grid-cols-1 lg:grid-cols-6 items-start gap-6">
      {timetable.map((day) => (
        <div className="grid grid-flow-row">
          <div
            className="uppercase text-heading border-b-2 mb-2 pb-2 font-light text-lg"
            key={day.day_of_week}
          >
            {day.day_of_week}
          </div>
          <div className="pt-2 lg:space-y-4 grid grid-cols-3 space-x-2 lg:space-x-0 lg:grid-cols-1">
            {day.sessions.map((session) =>
              session.type === "blank" ? (
                <div
                  className="h-24 bg-transparent"
                  key={`${day.day_of_week}-${session.time_slot_name}`}
                />
              ) : (
                <div
                  key={`${day.day_of_week}-${session.time_slot_name}`}
                  className={classNames(
                    "h-24 relative items-center justify-center flex cursor-pointer",
                    session.type === "other-location"
                      ? "bg-feat-lightgray-200 text-feat-darkgray-300 hover:bg-feat-lightgray"
                      : "bg-feat-blue bg-opacity-70 hover:bg-opacity-100"
                    // : "bg-feat-lightgray-400"
                  )}
                  role="button"
                  onClick={() =>
                    modal.openWorkoutModalOpen(session.session_short_code)
                  }
                >
                  <span className="absolute top-0 left-0 p-2  text-xs uppercase">
                    {session.time_slot_name}
                  </span>
                  <span className="flex flex-col px-2 py-6">
                    {session.session.split(" - ").map(
                      (text, index, arr) =>
                        text && (
                          <span
                            className={classNames(
                              "uppercase",
                              arr.length === 1 && text.length > 20
                                ? "text-sm lg:text-base lg:leading-tight pt-2 2xl:text-lg 2xl:pt-0"
                                : "pt-2 lg:text-lg lg:leading-tight",
                              {
                                "font-light text-feat-darkgray-700 leading-tight":
                                  arr.length === 1,
                                "font-semibold text-md":
                                  arr.length === 2 && index === 1,
                                "text-sm": arr.length === 2 && index === 0,
                              }
                            )}
                          >
                            {text}
                          </span>
                        )
                    )}
                  </span>
                </div>
              )
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
